<template>
  <!-- prettier-ignore -->
  <index-content :breadcrumb-items="[$t(`menu.customer.customer_message_device`)]">
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-text v-model="condition.deviceId" label="deviceId" />
      <n-field-enum v-model="condition.status" enum-name="CustomerDeviceStatus" label="status" all-option />
      <n-field-text v-model="condition.token" label="token" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-10">{{ $t('label.company') }}</th>
            <th class="col-weight-10">{{ $t('label.customerId') }}</th>
            <th class="col-weight-10">{{ $t('label.accountId') }}</th>
            <th class="col-weight-10">{{ $t('label.mobileType') }}</th>
            <th class="col-weight-10">{{ $t('label.deviceStatus') }}</th>
            <th class="col-weight-36">{{ $t('label.messageToken') }}</th>
            <th class="n-table-timestamp">{{ $t('label.deviceTime') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.profile" :value="row.customerId" />
            </td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.account" :value="row.accountId" />
            </td>
            <td v-enums:MobileType="row.mobileType"></td>
            <td v-enums:CustomerDeviceStatus="row.deviceStatus"></td>
            <td class="n-ellipsis" :title="row.messageToken">{{ row.messageToken }}</td>
            <td>{{ row.deviceTime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalCustomerMessageDevices from './ModalCustomerMessageDevices';
import { fetch } from '@/api/customer/customer-customer-message-devices';

export default CustomerView.extend({
  name: 'CustomerCustomerMessageDevices',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: null,
        customerId: null,
        accountId: null,
        deviceId: null,
        status: 0,
        token: null,
        from: this.sod,
        to: this.eod,
      },
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    openModal(model = {}) {
      this.createModal(ModalCustomerMessageDevices, { model, on: this });
    },
  },
});
</script>
