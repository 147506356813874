import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/devices`, params);
}

export function validate(model) {
  return $post(`customer/device/validate`, model);
}

export function update(model) {
  return $post(`customer/device/${model.id}`, model);
}
