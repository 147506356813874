<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :sub-name="$t('device')">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" disabled />
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.deviceId" field-name="deviceId" label="deviceId" :error="error.deviceId" disabled />
        <n-field-id :value="model.accountId" field-name="accountId" label="accountId" :error="error.accountId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.mobileType" field-name="mobileType" enum-name="MobileType" label="mobileType" :error="error.mobileType" disabled />
        <n-field-mask-enum v-model="model.messageTypes" field-name="messageTypes" type-name="CustomerMessageTypes" label="messageTypes" :error="error.messageTypes" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.deviceStatus" field-name="deviceStatus" enum-name="CustomerDeviceStatus" label="deviceStatus" :error="error.deviceStatus" :disabled="disabled" />
        <n-field-datetime :value="model.deviceTime" field-name="deviceTime" label="deviceTime" :error="error.deviceTime" disabled />
      </n-row-col2>
      <n-row-col1>
        <n-field-text v-model="model.messageToken" field-name="messageToken" label="messageToken" :error="error.messageToken" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { update, validate } from '@/api/customer/customer-customer-message-devices';

export default NModal.extend({
  name: 'ModalCustomerMessageDevices',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return update(model);
    },
  },
});
</script>
